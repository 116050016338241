
import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routers'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('setRouterName', to.name)
    document.title = to.meta.title == '' || !to.meta.title ? '锶源昆仑' : to.meta.title + ' | 锶源昆仑'
    if(to.meta.auth){
        next({name: 'Login'})
        return
    }else{
        // console.log('不需要登录')
    }
    next()
})

export default router
