
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        routerName: 'Index'
    },
    getters: {
        getRouterName: state => {
            return state.routerName
        }
    },
    mutations: {
        setRouterName(state, value) {
            state.routerName = value
        }
    }
})

export default store;
