<template>
    <div>
        <div class="footer" style="background-image: url(https://api.siyuankunlun.com/storage/materials/2022/10/20/PCfooter.jpg);">
            <div class="footer-cont">
                <div class="content">
                    <div class="footer-text">
                        <div class="footer-pointer footer-label">
                            <div class="footer-label-name"><span>产品</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'ProductBrief'}" @click="tapNav('ProductBrief','产品优势')"><span>产品介绍</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'ProductEdge'}" @click="tapNav('ProductEdge','产品优势')"><span>产品优势</span></div>
                        </div>
                        <div class="footer-pointer footer-label">
                            <div class="footer-label-name"><span>服务</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'ProductManual'}" @click="tapNav('ProductManual','产品手册')"><span>产品手册</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'ServiceIdea'}" @click="tapNav('ServiceIdea','服务理念')"><span>服务理念</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'ServiceAgree'}" @click="tapNav('ServiceAgree','服务协议')"><span>服务协议</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'LegalNotice'}" @click="tapNav('LegalNotice','法律声明')"><span>法律声明</span></div>
                        </div>
                        <div class="footer-pointer footer-label">
                             <div class="footer-label-name"><span>案例</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'CustoMcase'}" @click="tapNav('CustoMcase','客户案例')"><span>客户案例</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'Partners'}" @click="tapNav('Partners','合作伙伴')"><span>合作伙伴</span></div>
                        </div>
                        <div class="footer-pointer footer-label">
                            <div class="footer-label-name"><span>新闻</span></div>
                            <!-- <div class="footer-label-url"><span>产品动态</span></div> -->
                            <div class="footer-label-url" :class="{active : typeNav == 'MediaCoverage'}" @click="tapNav('MediaCoverage','媒体报道')"><span>媒体报道</span></div>
                            <div class="footer-label-url" :class="{active : typeNav == 'OtherDynamics'}" @click="tapNav('OtherDynamics','其他动态')"><span>其他动态</span></div>
                        </div>
                        <div class="footer-label footer-label-see">
                            <div class="footer-label-name"><span>联系</span></div>
                            <span>生产商：青海原宇水业发展有限公司都兰圣地天然饮用水厂</span>
                            <span>生产地址：青海省海西州都兰县香加乡科学图村恰藏沟</span>
                            <span>公司电话：13542083986</span>
                        </div>
                    </div>
                    <img class="footer-img" src="https://api.siyuankunlun.com/storage/materials/2022/10/20/PClogo.png">
                </div>
            </div>
        </div>

        <div class="footerBottom">
            <div class="content">
                <div class="footerBottom-left">
                    <span><a href="https://beian.miit.gov.cn">粤ICP备2022095164号-1</a></span>
                    <span>技术支持：域展科技</span>
                </div>
                <div class="footerBottom-right">
                    <!-- 公众号 + 微博 -->
                    <div class="official">
                        <img class="footerBottom-icon" src="../assets/footer_01.png" @click="Popup('wechat')">
                        <div class="official-img" v-if="wechatShow">
                            <img src="https://api.siyuankunlun.com/storage/materials/2022/10/21/official.jpg">
                        </div>
                    </div>

                    <!-- 微博 -->
                    <div class="official">
                        <img class="footerBottom-icon" src="../assets/footer_02.png" @click="Popup('weibo')">
                        <div class="official-img" v-if="weiboShow">
                            <img src="https://api.siyuankunlun.com/storage/materials/2022/10/21/weibo.png">
                        </div>
                    </div>

                    <!-- 邮箱 -->
                    <div class="official">
                        <img class="footerBottom-icon" src="../assets/footer_03.png" @click="Popup('mailbox')">
                        <div class="official-img" v-if="mailboxShow">
                            <img src="https://api.siyuankunlun.com/storage/materials/2022/10/21/mailbox.png">
                        </div>
                    </div>

                    <!-- 分享 -->
                    <!-- <div class="official">
                        <img class="footerBottom-icon" src="../assets/footer_04.png" @click="Popup('')">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { unix } from 'moment'

export default {
    name: 'appFooter',
    data() {
        return {
            wechatShow  : false,
            weiboShow   : false,
            mailboxShow : false,
            typeNav     : ''
        }
    },
    created(){
        this.typeNav = this.$store.state.routerName
    },
    methods: {
        Popup(e) {
            if(e == 'wechat') {
                this.wechatShow  = !this.wechatShow
                this.weiboShow   = false
                this.mailboxShow = false
            }else if(e == 'weibo'){
                this.weiboShow   = !this.weiboShow
                this.wechatShow  = false
                this.mailboxShow = false
            } else {
                this.mailboxShow = !this.mailboxShow
                this.wechatShow  = false
                this.weiboShow   = false
            }
        },
        // 跳转
        tapNav(e,newtitle) {
            this.typeNav = e
            if(e == 'MediaCoverage') {
                window.location.href = 'https://travel.sohu.com/a/592725135_121375027'
                return
            }

            // 实现滚动效果
            let top = document.documentElement.scrollTop || document.body.scrollTop
            const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50
            if (top <= 0) {
                clearInterval(timeTop)
            }
            }, 10)
            
            this.$router.push({
                name: e,
                params: {title: newtitle}
            })
        }
    }
}
</script>

<style>
.footer {
    padding: 50px 0;
    box-sizing: border-box;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.footer-img {
    width: 174px;
    height: 174px;
}

.footer-text {
    width: calc(100% - 174px);
    display: flex;
}

.footer-label {
    margin-right: 50px;
}

.footer-label span {
    /* background-image:-webkit-linear-gradient(left,#a7845e,#f0d5af);  */
    /* -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent;  */
    display: block;
    line-height: 24px;
    font-size: 13px;
}

.footer-label-see {
    color: #e2c8a5;
}

.footer-pointer>span {
    cursor: pointer;
}

.footer-label-name>span {
    font-size: 20px !important;
    margin: 20px 0;
    font-weight: 600;
    color: #e2c8a5;
}
.footerBottom {
    background-image: linear-gradient(to top, #a89172 , #f8e6d0);
    height: 48px;
    line-height: 48px;
}
.footerBottom .content {
    display: flex;
}
.footerBottom-left {
    color: #000;
    font-size: 12px;
    flex: 1;
}
.footerBottom-left a {
    color: #000 !important;
}
.footerBottom-left span {
    padding-right: 20px;
}
.footerBottom-right {
    display: flex;
}
.footerBottom-icon {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    cursor: pointer;
}

/* 公众号 */
.official {
    position: relative;
}
.official-img {
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    top: -110px;
    left: -25px;
    z-index: 9;
    padding: 5px;
    box-sizing: border-box;
}
.official-img img {
    width: 100%;
}

.footer-label-url {
    padding-top: 1px;
}

.footer-label-url span {
    background-color: transparent;
    color: #f0d3b1;
    border-radius: 100px;
    height: 16px;
    line-height: 16px !important;
    padding: 0 5px;
    margin-top: 6px;
    cursor: pointer;
}

.footer-label-url:hover span, .footer-label-url.active span {
    background-color: #f0d3b1;
    color: #000;
} 

</style>
