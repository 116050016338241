
export const routes = [
    {
        path: '/',
        name: 'Index',
        meta: { title: '',},
        component: () => import('@/views/index')
    },
    {
        path: '/product',
        name: 'Product',
        meta: { title: '',},
        component: () => import('@/views/product')
    },
    {
        path: '/club',
        name: 'Club',
        meta: { title: '',},
        component: () => import('@/views/club')
    },
    {
        path: '/water',
        name: 'Water',
        meta: { title: '',},
        component: () => import('@/views/water')
    },
    {
        path: '/science',
        name: 'Science',
        meta: { title: '',},
        component: () => import('@/views/science')
    },
    {
        path: '/about',
        name: 'About',
        meta: { title: '',},
        component: () => import('@/views/about')
    },
    {
        path: '/details/:id/:title',
        name: 'Details',
        meta: { title: '',},
        component: () => import('@/views/details')
    },
    {
        path: '/productBrief',
        name: 'ProductBrief',
        meta: { title: '',},
        component: () => import('@/views/productBrief')
    },
    {
        path: '/productEdge',
        name: 'ProductEdge',
        meta: { title: '',},
        component: () => import('@/views/productEdge')
    },
    {
        path: '/productManual',
        name: 'ProductManual',
        meta: { title: '',},
        component: () => import('@/views/productManual')
    },
    {
        path: '/serviceIdea',
        name: 'ServiceIdea',
        meta: { title: '',},
        component: () => import('@/views/serviceIdea')
    },
    {
        path: '/serviceAgree',
        name: 'ServiceAgree',
        meta: { title: '',},
        component: () => import('@/views/serviceAgree')
    },
    {
        path: '/legalNotice',
        name: 'LegalNotice',
        meta: { title: '',},
        component: () => import('@/views/legalNotice')
    },
    {
        path: '/custoMcase',
        name: 'CustoMcase',
        meta: { title: '',},
        component: () => import('@/views/custoMcase')
    },
    {
        path: '/partners',
        name: 'Partners',
        meta: { title: '',},
        component: () => import('@/views/partners')
    },
    {
        path: '/otherDynamics',
        name: 'OtherDynamics',
        meta: { title: '',},
        component: () => import('@/views/otherDynamics')
    }
]
