<template>
    <div class="nav">
        <div class="content">
            <div class="nav-label nav-left">
                <!-- 电话 -->
                <div class="headPop">
                    <img class="footerTop-icon" src="../assets/header_01.png" @click="Popup('telShow')">
                    <div class="headPop-text" v-if="telShow" @click="copyData">
                        {{telText}}
                    </div>
                </div>

                <!-- 邮箱 -->
                <div class="headPop">
                    <img class="footerTop-icon" src="../assets/header_03.png" @click="Popup('mailbox')">
                    <div class="headPop-img" v-if="mailboxShow">
                        <img src="https://api.siyuankunlun.com/storage/materials/2022/10/21/mailbox.png">
                    </div>
                </div>

                <!-- 地址 -->
                <div class="headPop" @click="siteGo">
                    <img class="footerTop-icon" src="../assets/header_02.png">
                </div>
            </div>
            <div class="nav-label nav-center">
                <img src="../assets/header_logo.png">
            </div>
            <!-- <div class="nav-label nav-right">
                <img src="../assets/header_04.png">
                <img src="../assets/header_05.png">
            </div> -->
        </div>
    </div>
</template>

<script>
    import bus from '../store/bus'
    export default{
        name: 'appHeader',
        data(){
            return {
                telText     : '13542083986',
                telShow     : false,
                mailboxShow : false
            }
        },
        methods: {
            Popup(e) {
                if(e == 'telShow') {
                    this.telShow     = !this.telShow
                    this.mailboxShow = false
                } else {
                    this.mailboxShow = !this.mailboxShow
                    this.telShow     = false
                }
            },
            siteGo() {
                // 修改nav组件中typeNav的值
                bus.$emit("typeNav", 'About')
                
                // 跳转联系我们
                this.$router.push({name: 'About'})
            },

            copyData(){
                this.$copyText(this.telText).then( e => {
                    this.$message({
                        title:'成功',
                        message:'...成功',
                        type:'seccess',
                        duration:2000
                    })
                }, function (e) {})
            }
        }
    }
</script>

<style>
    .nav {
        background-image: linear-gradient(to top, #a89172 , #f8e6d0);
        height: 54px;
        position: relative;
        left: 0;
        top: 0;
    }

    .content {
        display: flex;
    }

    .nav-label {
        width: 33.33%;
    }

    .footerTop-icon {
        width: 18px;
        width: 18px;
        cursor: pointer;
        margin: 0 15px;
        margin-top: 17px;
    }

    .headPop {
        position: relative;
        display: inline-block;
    }
    .headPop-img {
        position: absolute;
        background-color: #fff;
        border-radius: 4px;
        width: 100px;
        height: 100px;
        top: 54px;
        left: -30px;
        z-index: 9;
        padding: 5px;
        box-sizing: border-box;
    }
    .headPop-text {
        position: absolute;
        background-color: #fff;
        border-radius: 4px;
        top: 54px;
        left: -30px;
        z-index: 9;
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .headPop-img::after,
    .headPop-text::after {
        content: '';
        position: absolute;
        top: -8px;
        left: calc(50% - 6px);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;  
        border-right: 8px solid transparent;  
        border-bottom: 8px solid #fff; 
    }
    .headPop-img img {
        width: 100%;
    }

    .nav-right {
        text-align: right;
    }

    .nav-right img {
        margin-left: 20px;
    }

    .nav-center {
        text-align: center;
    }

    .nav-center img {
        width: 78px;
        margin-top: 5px;
    }
</style>
