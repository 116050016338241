<template>
    <div class="navList">
        <div class="content">
            <div class="navList-label">
                <img :src="typeNav == 'Index' ? 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_01_active.png' : 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_01.png'" @click="tapNav('Index')">
                <img :src="typeNav == 'Product' ? 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_02_active.png' : 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_02.png'" @click="tapNav('Product')">
                <img :src="typeNav == 'Club' ? 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_03_active.png' : 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_03.png'" @click="tapNav('Club')">
                <img :src="typeNav == 'Water' ? 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_04_active.png' : 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_04.png'" @click="tapNav('Water')">
                <img :src="typeNav == 'Science' ? 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_05_active.png' : 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_05.png'" @click="tapNav('Science')">
                <img :src="typeNav == 'About' ? 'https://api.siyuankunlun.com/storage/materials/2022/10/20/nav_06_active.png' : 'https://api.siyuankunlun.com/storage/materials/2022/10/21/7ac515881e176f5c4d30f1c0960fcef5.png'" @click="tapNav('About')">
            </div>
        </div>
    </div>
</template>

<script>
import bus from '../store/bus'
export default {
    name: 'appNav',
    data() {
        return {
            typeNav: 'Index'
        }
    },
    created(){
        this.typeNav = this.$store.state.routerName
        bus.$on('typeNav', param => {
            this.typeNav = param
        })
    },
    methods: {
        // 跳转
        tapNav(e) {
            this.typeNav = e
            this.$router.push({
                name: e
            })
        }
    }
}
</script>

<style>
.navList {
    position: absolute;
    top: 54px;
    left: 0;
    width: 100%;
}

.navList-label {
    padding: 60px 30px 0;
    box-sizing: border-box;
}

.navList-label img {
    width: 11%;
    margin-right: 20px;
    cursor: pointer;
}
</style>
