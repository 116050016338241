<template>
  <div id="app">
    <appHeader />
    <appNav />
    <router-view></router-view>
    <appFooter />
  </div>
</template>
<script>
    const s=document.createElement('script');
        s.type='text/javascript';
        s.src= 'https://map.qq.com/api/gljs?v=1.exp&key=AC2BZ-LTPCF-LHOJ2-NSX4S-BDEEF-EPFWA';
        document.body.appendChild(s);
  import appHeader from '@/components/header.vue'
  import appFooter from '@/components/footer.vue'
  import appNav from '@/components/nav.vue'
  export default {
    name: 'App',
    components: {
        appHeader,
        appFooter,
        appNav
    },
    data() {
        return {}
    },

    mounted () {
      // 根据不同路由跳转不同页面
      let goUrl = this.isMobile();
      if (goUrl === 1) {
          //移动端地址
          location ='https://www.siyuankunlun.com/h5/';
      }else{
          // 显示打开的页面的列表
          this.$router.push({path: '/'})
      }
    },
    methods: {
      isMobile(){
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        localStorage.setItem('ismobile',flag?1:0);
        let goUrl = flag ? 1 : 0;
        return goUrl;
      }
    }
  }
</script>

<style>
    /* 公共标签 */
    body {
        padding:0;
        margin:0;
        background:#f5f5f5 !important;
    }
    ul {
        list-style:none;
        padding:0;
        margin:0;
    }
    img {
        width:100%;
    }
    input,button,a,select {
        outline:none
    }
    select:focus,textarea {
        outline:none;
    }
    .ce-img {
        position:relative;
    }
    .ce-img>span {
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
    }
    .ce-nowrap {
        max-width:100%;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis
    }
    .ce-nowrap-multi {
        display:-webkit-box;
        overflow:hidden;
        text-overflow:ellipsis;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2
    }
    .content {
        width:1200px;
        margin:0 auto;
    }
</style>
